<template>
  <div class="is-shadowless widget-localtime" >

    <div class="is-flex is-align-items-center">
      <div class="widget-localtime-item has-text-weight-semibold" :class="{ 'is-last': state.isSwitch }" >
        <img src="@/assets/img/switzerland.svg" class="is-16" />
        <span>{{ state.sw_time }}</span>
      </div>

      <div class="widget-localtime-item has-text-weight-semibold">
          <img src="@/assets/img/vietnam.svg" class="is-16" />
          <span>{{ state.vn_time }}</span>
      </div>
    </div>

    <div class="has-text-weight-normal is-size-7">{{ state.today }}</div>
  </div>
</template>

<script>
  import moment from 'moment-timezone';
import { reactive, nextTick } from 'vue';

  export default {

    setup() {
      const state = reactive({
        vn_time: '00:00',
        sw_time: '00:00',
        today: new Date(),
        isSwitch: false
      })

      const getTime = (location, format) => {
        return moment().tz(location).format(format);
      }

      const getToday = () => {
        return moment().format("[Today is] dddd, DD MMM");
      }

      state.vn_time = getTime('Asia/Ho_Chi_Minh','LT');
      state.sw_time = getTime('Europe/Zurich','LT');

      state.today = getToday();


      fetch('https://extreme-ip-lookup.com/json/')
        .then( res => res.json())
        .then(response => {
            if (response.continent === 'Asia') {
              state.isSwitch = true
            }
        })
        .catch((data, status) => {
            console.log('Request failed');
        })



      setInterval(() => {
        state.vn_time = getTime('Asia/Ho_Chi_Minh','LT');
        state.sw_time = getTime('Europe/Zurich','LT');
      }, 60000);


      return {
        state
      }
    }
  }

</script>

<style lang="scss" scoped>

  .widget-localtime {
    display: flex;
    align-content: center;
    align-items: center;

    &-item {
      display: flex;
      align-items: center;
      margin: 0 20px 0 10px;

      .is-16 {
        height: 24px !important;
        width: 100% !important;
        display: block;
        margin-right: 10px;
      }

      span {
        display: block;
        white-space: nowrap;
      }


      &.is-last {
        order: 1
      }
    }
  }
</style>